.detailView {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75); 
    display: flex;
    justify-content: center;
    align-items: center;

    .previousButton {
        position: fixed;
        left: 32px;
        top: 50%; 
        transform: translateY(-50%);

        @media ( max-width: 500px ) { left: 8px; }
    }
    
    .nextButton {
        position: fixed;
        right: 32px;
        top: 50%; 
        transform: translateY(-50%);

        @media ( max-width: 500px ) { right: 8px; }
    }
}

.detailImage {
    width: calc(100vw * 1.25);
    max-width: 80vw;
    max-height: 100vh;
    object-fit: contain;

    @media ( min-width: 1500px ) { max-height: 70vh; }
}