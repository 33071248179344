html, body, #root {
    width: 100%;
    margin: 0;
    overflow-x: hidden;
}

button {
    cursor: pointer;
    border: none;
    background: none;
}