.homeContainer {
    display: flex;
    height: 100vh;
}

.homeContent {
    display: flex;
    justify-content: center;
    font-family: "Libre Franklin", sans-serif;
    width: 100vw;

    .footerContainer {
        position: fixed;
        bottom: 8px;
    }
}