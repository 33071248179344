$titleSizeDesktop: 128px;
$titleSizeMobile: 96px;
$titleLineHeightDesktop: 88px;
$titleLineHeightMobile: 64px;

.titleContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
        font-family: "Modak", system-ui;
        font-weight: 400;
        font-style: normal;
        font-size: $titleSizeDesktop;
        line-height: $titleLineHeightDesktop/$titleSizeDesktop;
        width: 480px;
        transform: skewY(-16deg);
        text-align: center;

        @media (max-width: 700px) {
            font-size: $titleSizeMobile;
            line-height: $titleLineHeightMobile/$titleSizeMobile;
            width: 360px;
        }
    }
}

.redStyleTitle {
    color: #F29EAC;
}

.tealStyleTitle {
    color: #FAF5D8
}

.purpleStyleTitle {
    color: #FFD8B3;
}

.blueStyleTitle {
    color: #BD6AE3;
}

.greenStyleTitle {
    color: #B9FBEE;
}

.orangeStyleTitle {
    color: #F3DB99;
}