.topHeader {
  margin-top: 92px;
}

.galleryContainer {
  display: flex;

  p {
    font-family: "Libre Franklin", sans-serif;
    font-size: 24px;
    height: 40px;
    margin-left: 32px;
    margin-bottom: 0;
  }
}

.displayGallery {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.galleryGrid {
  margin: 0 32px;
  display: grid;
  grid-template-columns: repeat(6, minmax(200px,1fr));
  grid-auto-rows: 160px;
  grid-auto-flow: dense;
  gap: 16px;

  @media ( min-width: 1500px ) { grid-auto-rows: 300px; }

  @media ( max-width: 900px ) {
      grid-template-columns: repeat(3, 1fr);
      grid-auto-flow: row dense;
  }

  @media ( max-width: 500px ) {
    margin: 0;
    grid-template-columns: minmax(200px,1fr);
    grid-auto-rows: auto;
    grid-auto-flow: row dense;
    width: 100%;
}
  
  .wide {
    grid-column: span 2;
  }

  .tall {
    grid-row: span 2;

    @media ( max-width: 900px ) { grid-row: span 1; }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.galleryPage {
  background-color: #f9f8f7;

  .copyrightFooter {
    text-align: center;
    text-wrap: wrap;
    width: 100%;
    display: flex;
    justify-content: center;
  
    p {
        font-size: 12px;
        color: rgb(113, 110, 110);
        margin: 8px 32px;
    }
  }
}

.closeButton {
  position: fixed;
  top: 32px;
  right: 32px;
}